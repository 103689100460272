
* {
  margin: 0;
  box-sizing: border-box;
}

body * {
  position: relative;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* no because Safari iOS and its strange height */
  /* min-height: 100vh; */
}

body {
  min-height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.window-min-height {
  min-height: 100vh;
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-contain, /* obsolote, to be removed */
.background-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.image-cover, /* obsolote, to be removed */
.background-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.no-click {
  pointer-events: none;
}

.click-through {
  pointer-events: none;
}
.click-through > *:not(.click-through) {
  pointer-events: all;
}






.button {
  cursor: pointer;
  user-select: none;
}
/* No click for children, but for the button only. */
.button > *:not(.button) {
  pointer-events: none;
}
.button.disabled {
  pointer-events: none;
  /* no opacity plz, this is too much style specific */
  /* opacity: .5; */
}



/* Text options */
.uppercase {
  text-transform: uppercase;
}
