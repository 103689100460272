
.HUD .SearchCorner {
  --size: 2rem;
  --color: var(--ui-color);
  
  width: var(--size);
  height: var(--size);
  transform: translateX(.5em);
}

.HUD .SearchCorner:hover {
  --color: var(--ui-color-max);
}

.HUD .SearchCorner > div:not(.visible) {
  opacity: 0;
}

.HUD .SearchCorner svg.Search {
  fill: var(--color);
  transition: fill .15s ease-out;
}

.HUD .SearchCorner svg.Cross {
  stroke: var(--color);
  transition: stroke .15s ease-out;
  transform: scale(.75);
}

