.ProjectContent.Text {
}

.ProjectContent.Text :is(h1, h2, h3, h4) {
  font-family: 'Fraktion Mono';
}

.ProjectContent.Text > div {
  padding: 0 var(--grid-inset);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ProjectContent.Text > div *:not(:last-child) {
  margin-bottom: var(--vertical-gap);
}

.ProjectContent.Text > div blockquote {
  font-style: italic;
  font-size: 1.33em;
  padding: 0 2em;
}

.ProjectContent.Text a {
  color: var(--ui-link-max);
  transition: color .2s ease-out;
}

.ProjectContent.Text a:is(:hover, :hover:visited) {
  color: var(--ui-link-hover);
}

.ProjectContent.Text a:visited {
  color: var(--ui-link);
}
