.SearchProject {
  padding-top: var(--grid-padding-top);
  padding-bottom: var(--grid-padding-bottom);
  overflow: hidden;
  user-select: none;
}

.mobile .SearchProject {
  padding-bottom: 0;
}

.SearchProject .Header {
  z-index: 10;
}

.SearchProject .SliderOrPixelButton {
  width: 32px;
  height: 32px;
}

.SearchProject .HeaderComboBar {
  position: fixed;
  transform: translate(0, -50%);
  width: 100%;
  justify-content: center;
}

.SearchProject .HeaderComboBar .ProjectCount {
  font-size: 26px;
  transform: translateY(-1.5px);
  color: var(--ui-color-dim);
  padding: 4px 0;
  width: 30px;
  text-align: center;
}

.SearchProject .HeaderComboBar svg {
  flex: 0 0 2em;
}

.SearchProject .HeaderComboBar .Label {
  flex: 1;
  line-height: 1em;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.SearchProject .HeaderComboBar:hover .Label {
  opacity: 1;
}
