
.fill {
  width: 100%;
  height: 100%;
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.children-fill > * {
  width: 100%;
  height: 100%;
  flex: 1;
}

.vh-100 {
  height: 100vh;
}

.overflow-hidden {
  overflow: hidden;
}

/* remove first and last margins over a text (that should be wrapped by any element) */
.fit-line-height > *:first-child {
  margin-top: -.25em;
}
.fit-line-height > *:last-child {
  margin-bottom: -.25em;
}

.padding-1em {
  padding: 1em;
}

.padding-2em {
  padding: 2em;
}

.padding-1 {
  padding: 1px;
}
.padding-2 {
  padding: 2px;
}
.padding-4 {
  padding: 4px;
}
.padding-6 {
  padding: 6px;
}
.padding-8 {
  padding: 8px;
}
.padding-10 {
  padding: 10px;
}
.padding-12 {
  padding: 12px;
}
.padding-14 {
  padding: 14px;
}
.padding-16 {
  padding: 16px;
}
.padding-24 {
  padding: 24px;
}
.padding-32 {
  padding: 32px;
}
.padding-48 {
  padding: 48px;
}
.padding-64 {
  padding: 64px;
}
.padding-96 {
  padding: 96px;
}

.padding-h-1 {
  padding-left: 1px;
  padding-right: 1px;
}
.padding-h-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.padding-h-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.padding-h-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.padding-h-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.padding-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-h-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.padding-h-14 {
  padding-left: 14px;
  padding-right: 14px;
}
.padding-h-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.padding-h-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.padding-h-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.padding-h-48 {
  padding-left: 48px;
  padding-right: 48px;
}
.padding-h-64 {
  padding-left: 64px;
  padding-right: 64px;
}
.padding-h-96 {
  padding-left: 96px;
  padding-right: 96px;
}

.text-center {
  text-align: center;
}

.pre {
  white-space: pre;
}

.overflow-hidden {
  overflow: hidden;
}

.flex {
  display: flex;
}

.flex.gutter {
  --gutter: 0;
}

.flex.row {
  flex-direction: row;
}
/* https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors */
.flex[class*="gutter"].row > * + * {
  margin-left: var(--gutter);
}

.flex.column {
  flex-direction: column;
}
/* Phew quite hard isn't it? not "absolute" is for ignore children like "absolute-fill". */
.flex.column[class*="gutter"] > * + *:not([class*="absolute"]) {
  margin-top: var(--gutter);
}

.flex.center {
  align-items: center;
  justify-content: center;
}

.flex.justify-center {
  justify-content: center;
}

.flex.justify-start {
  justify-content: flex-start;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-center {
  align-items: center;
}

.flex.align-stretch {
  align-items: stretch;
}

.flex.gutter-2 > * {
  --gutter: 2px;
}
.flex.gutter-4 > * {
  --gutter: 4px;
}
.flex.gutter-6 > * {
  --gutter: 6px;
}
.flex.gutter-8 > * {
  --gutter: 8px;
}
.flex.gutter-10 > * {
  --gutter: 10px;
}
.flex.gutter-12 > * {
  --gutter: 12px;
}
.flex.gutter-14 > * {
  --gutter: 14px;
}
.flex.gutter-16 > * {
  --gutter: 16px;
}
.flex.gutter-24 > * {
  --gutter: 24px;
}
.flex.gutter-32 > * {
  --gutter: 32px;
}
.flex.gutter-48 > * {
  --gutter: 48px;
}
.flex.gutter-64 > * {
  --gutter: 64px;
}

.flex > .stretch {
  align-self: stretch;
}

.flex > .expand {
  flex: 1;
  align-self: stretch;
}

.flex.expand-content > * {
  flex: 1;
}



.flex.row > .space-2 {
  flex: 0 0 2px;
  width: 2px;
  align-self: stretch;
}
.flex.row > .space-4 {
  flex: 0 0 4px;
  width: 4px;
  align-self: stretch;
}
.flex.row > .space-8 {
  flex: 0 0 8px;
  width: 8px;
  align-self: stretch;
}
.flex.row > .space-16 {
  flex: 0 0 16px;
  width: 16px;
  align-self: stretch;
}
.flex.row > .space-24 {
  flex: 0 0 24px;
  width: 24px;
  align-self: stretch;
}
.flex.row > .space-32 {
  flex: 0 0 32px;
  width: 32px;
  align-self: stretch;
}
.flex.row > .space-48 {
  flex: 0 0 48px;
  width: 48px;
  align-self: stretch;
}
.flex.row > .space-64 {
  flex: 0 0 64px;
  width: 64px;
  align-self: stretch;
}
.flex.row > .space-96 {
  flex: 0 0 96px;
  width: 96px;
  align-self: stretch;
}

.flex.column > .space-2 {
  flex: 0 0 2px;
  height: 2px;
  align-self: stretch;
}
.flex.column > .space-4 {
  flex: 0 0 4px;
  height: 4px;
  align-self: stretch;
}
.flex.column > .space-8 {
  flex: 0 0 8px;
  height: 8px;
  align-self: stretch;
}
.flex.column > .space-16 {
  flex: 0 0 16px;
  height: 16px;
  align-self: stretch;
}
.flex.column > .space-24 {
  flex: 0 0 24px;
  height: 24px;
  align-self: stretch;
}
.flex.column > .space-32 {
  flex: 0 0 32px;
  height: 32px;
  align-self: stretch;
}
.flex.column > .space-48 {
  flex: 0 0 48px;
  height: 48px;
  align-self: stretch;
}
.flex.column > .space-64 {
  flex: 0 0 64px;
  height: 64px;
  align-self: stretch;
}
.flex.column > .space-96 {
  flex: 0 0 96px;
  height: 96px;
  align-self: stretch;
}



.flex.row > .space-1em {
  flex: 0 0 1em;
  width: 1em;
  align-self: stretch;
}
.flex.row > .space-2em {
  flex: 0 0 2em;
  width: 2em;
  align-self: stretch;
}
.flex.row > .space-3em {
  flex: 0 0 3em;
  width: 3em;
  align-self: stretch;
}
.flex.row > .space-4em {
  flex: 0 0 4em;
  width: 4em;
  align-self: stretch;
}

.flex.column > .space-1em {
  flex: 0 0 1em;
  height: 1em;
  align-self: stretch;
}
.flex.column > .space-2em {
  flex: 0 0 2em;
  height: 2em;
  align-self: stretch;
}
.flex.column > .space-3em {
  flex: 0 0 3em;
  height: 3em;
  align-self: stretch;
}
.flex.column > .space-4em {
  flex: 0 0 4em;
  height: 4em;
  align-self: stretch;
}

.flex-1 {
  flex: 1;
}
