
.LiveInspector .Property {
  font-size: .8em;
  height: 16px;
}

.LiveInspector .Property .Label {
  flex: 0 0 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LiveInspector .Property .Key {
  padding-right: .2em;
}

.LiveInspector .Property .Label,
.LiveInspector .Property .Key {
  opacity: .66;
}

.LiveInspector .Property .Label,
.LiveInspector .Property .Value {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.LiveInspector .Property input[type=color] {
  width: 4em;
  height: 2em;
  opacity: 0;
}

.LiveInspector .Property input.NumberInput {
  width: 100%;
  cursor: col-resize;
}

.LiveInspector .Property .Checkbox input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
}

.LiveInspector .Property .Checkbox .SvgWrapper {
  font-size: 2em;
  pointer-events: none;
}

.LiveInspector .Property .Checkbox .SvgWrapper svg {
  transform: scale(1.1);
}
