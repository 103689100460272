.LargeScroller {
  flex: 1;
  width: 100%;
  height: 100%;
}

.LargeScroller .ScrollableContent {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.LargeScroller .WhiteGradient {
  pointer-events: none;

  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--grid-padding-bottom) + 8em);
  background-image: linear-gradient(0deg, 
    #ffff calc(var(--grid-padding-bottom) * .5 + .75em), 
    #fff0 100%);
}

.LargeScroller .Shadow {
  pointer-events: none;
  
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 3em;
  opacity: .25;
  mask-image: 
    linear-gradient(90deg, 
      #0000 2%, 
      #000f calc(8% + var(--grid-padding-left)), 
      #000f calc(100% - (8% + var(--grid-padding-right))), 
      #0000 98%);
  background-image:
    linear-gradient(
        #181428ff 0.0%,
        #181428db 5.0%,
        #181428ba 10.0%,
        #1814289d 15.0%,
        #18142883 20.0%,
        #1814286c 25.0%,
        #18142857 30.0%,
        #18142846 35.0%,
        #18142837 40.0%,
        #1814282a 45.0%,
        #18142820 50.0%,
        #18142817 55.0%,
        #18142810 60.0%,
        #1814280b 65.0%,
        #18142807 70.0%,
        #18142804 75.0%,
        #18142802 80.0%,
        #18142801 85.0%,
        #18142800 90.0%,
        #18142800 95.0%,
        #18142800 100.0%),
      linear-gradient(
        #181428d9 0.0%,
        #181428a8 2.5%,
        #18142880 5.0%,
        #18142860 7.5%,
        #18142847 10.0%,
        #18142833 12.5%,
        #18142824 15.0%,
        #18142819 17.5%,
        #18142811 20.0%,
        #1814280b 22.5%,
        #18142807 25.0%,
        #18142804 27.5%,
        #18142802 30.0%,
        #18142801 32.5%,
        #18142801 35.0%,
        #18142800 37.5%,
        #18142800 40.0%,
        #18142800 42.5%,
        #18142800 45.0%,
        #18142800 47.5%,
        #18142800 50.0%);
}