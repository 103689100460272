.QualityDebug {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 360px;
  padding: 16px;
  font-family: 'Fira Code', monospace;
  z-index: 100;
  font-size: .66em;
  line-height: 1.1em;
  background-color: black;
}

.QualityDebug .Close {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 10;
}
.QualityDebug .Close::before,
.QualityDebug .Close::after {
  content: '';
  position: absolute;
  left: calc((100% - 1px) / 2);
  width: 1px;
  height: 100%;
  background-color: currentColor;
  transform: rotate(45deg);
}
.QualityDebug .Close::after {
  transform: rotate(-45deg);
}

:root.mobile .QualityDebug {
  width: 100%;
  right: unset;
}

.light-theme .QualityDebug {
  background-color: white;
}