
.LiveInspector .Section h2 {
  padding: 2px 0;
}
.LiveInspector .Section h2::before {
  content: '►';
  padding-right: .5em;
  display: inline-block;
  --transform: scaleX(.5) scale(1.5);
  transform: translateY(-.15em) var(--transform);
}

.LiveInspector .Section:not(.collapsed) h2::before {
  transform: translate(-.1em, .15em) rotate(90deg) var(--transform);
}

.LiveInspector .Section .JsonButton {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 0 2px;
}

.LiveInspector .Section .JsonLayer {
  background-color: var(--ui-background-color-default);
}

.LiveInspector .Section button:hover {
  opacity: 1;
}

.LiveInspector .Section button {
  font-size: .8em;
  opacity: .66;
}

.LiveInspector .Section .JsonContainer {
  font-size: .8em;
  white-space: pre;
  overflow: hidden;
  padding: 4px;
}