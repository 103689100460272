.OvvoLogo {
  position: absolute;
  transition: position 0s linear;
}

.OvvoLogo svg {
  height: 18px;
  fill: var(--ui-current-color);
  transition: var(--ui-color-transition);
}

.OvvoLogo .Baseline {
  text-transform: uppercase;
  padding-left: 16px;
}