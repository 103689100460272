
.Snackbar {
  --width: 300px;
  position: fixed;
  top: 32px;
  left: calc((100% - var(--width)) / 2);
  width: var(--width);
  padding: 32px;
  background-color: #131414;
  color: var(--ui-tertiary-color);
  text-align: center;
  box-shadow: #0006 0 0 64px;
  transition: all .5s cubic-bezier(.4, 0, .3, 1);
}

.Snackbar.out {
  transform: translateY(calc(-30%));
  opacity: 0;
}
