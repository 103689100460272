@import url(some-utils/css/main.css);
@import url(some-utils/css/layout.css);
@import url(some-utils/css/inputs.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, body * {
  margin: 0;
  box-sizing: border-box;
  position: relative;
}

.fullframe {
  width: 100vw;
  height: var(--vh);
}
