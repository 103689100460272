
.SearchProject .SearchInput {
  --width: 300px;
  --height: 60px;
  --top: 140px;
  position: absolute;
  top: calc((var(--top) - var(--height)) / 2);
  left: calc((100% - var(--width)) / 2);
  width: var(--width);
  height: var(--height);
}

.column-1 .SearchProject .SearchInput {
  top: 54px;
  z-index: 10;
}

.SearchProject .SearchInput .SearchSvg {
  position: absolute;
  transform: scale(1.2);
  transition: transform .2s ease-in-out;
  transition-delay: .2s;
}
.SearchProject .SearchInput.filled .SearchSvg,
.SearchProject .SearchInput.focused .SearchSvg {
  transform: scale(0);
  transition-delay: 0s;
}

.SearchProject .SearchInput .Line {
  position: absolute;
  bottom: 12px;
  width: 200px;
  left: calc(50% - 100px);
  height: 1px;
  background-color: var(--ui-color-dim);
  opacity: .66;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0, .5, .25, 1);
}

.SearchProject .SearchInput:not(.focused) {
  cursor: pointer;
}

.SearchProject .SearchInput.filled .Line,
.SearchProject .SearchInput.focused .Line {
  transform: scaleX(1);
  transition-delay: .2s;
}



.SearchProject .SearchInput .ClearDoubleArrowSvg {
  position: absolute;
  right: 0;
}

.SearchProject .SearchInput input {
  width: 100%;
  height: 100%;
  font-size: 18px;
  /* opacity: 0; */
  transition: opacity .3s cubic-bezier(0, .5, .25, 1);
  text-transform: uppercase;
  transform: translateY(-.066em);
}

.SearchProject .SearchInput.filled input,
.SearchProject .SearchInput.focused input {
  opacity: 1;
  transition: opacity .6s cubic-bezier(.25, 0, .25, 1);
  transition-delay: .3s;
}


/* responsive */
/* .mobile .SearchProject .SearchInput {
  --width: 40px;
  --height: 40px;
  left: unset;
  right: 60px;
  justify-content: flex-end;
} */