.AutoScrollBar {
  --margin: 4px;
  position: fixed;
  bottom: var(--margin);
  left: var(--margin);
  right: var(--margin);
  height: 1px;
  opacity: 0;
  background-color: currentColor;
  transition: opacity 0.2s ease-out;
  transform-origin: 0%;
}

.AutoScrollBar.visible {
  opacity: 0.75;
}
