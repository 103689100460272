
.MediaCaption {
  padding-left: var(--grid-inset);
  margin-left: -8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-top: 1em; */
  color: #fff;
}

.MediaCaption svg {
  fill: currentColor;
  margin-right: 4px;
}

