.App .ProjectCover {
  width: 100%;
  height: var(--vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App .ProjectCover > div {
  /* padding-left: 52px; */
  height: 100%;
  /* transform: translateY(.55em); */
}

.column-1 .App .ProjectCover > div {
  /* padding-left: 16px; */
}

.App .ProjectCover .Spacing {
  height: 60px;
  align-self: stretch;
}

.mobile .App .ProjectCover .Spacing {
  height: 20px;
  align-self: stretch;
}

.App .ProjectCover .Title {
  text-align: left;
  transform: translateX(-0.05em);
  margin-top: -.3em;
}

.mobile .App .ProjectCover .Title {
  font-size: 3em;
}

.App .ProjectCover .Subtitle {
  padding-top: 20px;
  text-align: left;
}

.App .ProjectCoverDescription {
  margin-bottom: var(--vertical-gap);
  max-width: calc(var(--column-width) * 4 - 2 * var(--grid-inset));
}

.column-1 .App .ProjectCover .Title,
.column-1 .App .ProjectCover .Subtitle,
.column-1 .App .ProjectCoverDescription {
  padding-left: var(--grid-inset);
  padding-right: var(--grid-inset);
}
