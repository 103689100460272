
.Media {
  user-select: none;
  height: 100%;
}

.Media.disabled {
  pointer-events: none;
}

.Media img,
.Media video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
