
.ProjectContent.ContentError,
.ProjectContent .Error {
  background-color: #dc143c28;
  padding: 2em;

  .line-0 {
    font-size: 2em;
  }

  & a, & a:visited {
    color: #f009;
  }
  & a:hover {
    color: #f00;
  }
}
