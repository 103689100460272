.OvvoFooter .Inner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 32px;
  padding-top: 64px;
  background-color: black;
  /* background-color: #060606dd;
  backdrop-filter: blur(20px); */
  color: #ccc;
}
.OvvoFooter .Background {
  background-color: #0005;
}

.OvvoFooter .Contact {
  padding-top: 48px;
}
.OvvoFooter .Contact .button {
  color: var(--ui-tertiary-color);
  text-decoration: none;
}
/* :not(.touch) .OvvoFooter .Contact .button:hover {
  text-decoration: underline;
} */

.OvvoFooter .Companies {
  height: 176px;
}
.OvvoFooter .Companies .Company:hover {
  color: white;
}
.OvvoFooter .Companies .Company.selected {
  color: var(--ui-secondary-color);
}
.OvvoFooter .Companies .Company {
  padding-top: 16px;
  align-items: center;
}
.OvvoFooter .Companies .Company .Location {
  font-size: 8px;
  letter-spacing: 0.4em;
}
.OvvoFooter .Companies .Company > h3 {
  padding: 16px;
}
