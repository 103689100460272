.SearchProject .SliderView {
  --slider-top-margin: 18%;
  --texts-margin-top: calc(var(--grid-padding-bottom) / 2);
}

.desktop .SearchProject .SliderView {
  --slider-top-margin: 20%;
}

.mobile .SearchProject .SliderView {
  --slider-top-margin: 42px;
  --texts-margin-top: 88px;
}
