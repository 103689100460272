
@font-face {
  font-family: 'Fraktion Mono';
  font-weight: 400;
  src: url(PPFraktionMono-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Mono';
  font-weight: 500;
  src: url(PPFraktionMono-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Mono';
  font-weight: 600;
  src: url(PPFraktionMono-Semibold.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Mono';
  font-weight: 700;
  src: url(PPFraktionMono-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Mono';
  font-weight: 900;
  src: url(PPFraktionMono-Black.woff2) format('woff2');
}
