
.Showreel.permanent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.Showreel.permanent .Cover {
  pointer-events: none;
  transition: opacity .25s ease-out;
}

.Showreel.permanent .Cover:not(.active) {
  opacity: 0;
}

.Showreel.permanent .Cover.active.player-is-ready {
  animation: cover-fadeout .8s ease-out .5s forwards;
}

@keyframes cover-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
