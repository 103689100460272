
.IconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 6px !important;
  font-size: 12px;
  font-weight: 600;
}

.IconWrapper svg {
  fill: var(--ui-current-color);
  color: var(--ui-current-color);
  transition: var(--color-transition);
}

.IconWrapper:hover svg {
  transition: none;
}
