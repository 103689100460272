
.NewsFullPage {
  height: var(--vh);
}
  
.NewsFullPage .Wrapper {
  overflow: hidden scroll;
}

.NewsFullPage .Cover {
  height: var(--vh);
  justify-content: center;
}
  
.NewsFullPage .Cover .Wrapper {
  padding-left: 4em;
  justify-content: space-between;  
}

:root.mobile .NewsFullPage .Cover .Wrapper {
  padding-left: 0;
}

.NewsFullPage .Cover .NewsCategory {
  font-size: 1em;
  font-weight: 900;
  }
  
.NewsFullPage .Cover .Date {
  font-size: 2em;
  font-weight: 900;
}

.NewsFullPage .Cover .Description {
  padding: 0; /* Fixing the padding */
  width: 50%;
}
