.Route404 {
  font-size: 21px;
  text-align: center;
}

.Route404 .Bar {
  margin: .5em;
  width: 50%;
  height: 1.5px;
  background-color: currentColor;
}

:root.column-3 .Route404 {
  font-size: 28px;
}

:root:is(.column-4, .column-5) .Route404 {
  font-size: 36px;
}
