
.PlayerWrapper {
  background-color: black;
}

.MobileReel .Wrapper {
  background-color: black;
}

.MobileReel .UI {
  padding-top: var(--grid-padding-top);
  display: flex;
  flex-direction: column;
}

.MobileReel .UI .Spacing {
  flex: 0 0 4.5em;
}

.MobileReel .UI h1 {
  text-transform: uppercase;
  text-align: center;
  color: var(--ui-color-max);
  font-size: 2.4em;
  letter-spacing: .2em;
}

.MobileReel .UI ul {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 0;
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: .2em;
  gap: 1em;
}

.MobileReel .UI .PlayButton {
  padding: 2em;
  align-self: center;
}

.MobileReel .UI .PlayButton svg {
  width: 4.5em;
  height: 4.5em;
}

.MobileReel .UI .OrientationIcon {
  align-self: center;
}

.MobileReel .UI .OrientationIcon svg {
  width: 3em;
  height: 3em;
}