.ProgressionLadder {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
}

.ProgressionLadder .button {
  pointer-events: all;
}
