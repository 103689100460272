.MobilePermanentSearch {
  --size: 2em;
  position: absolute;
  left: calc(50% - var(--size) / 2);
  top: calc((var(--grid-padding-top) - var(--size)) / 2);
  width: var(--size);
  height: var(--size);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity .8s ease-in-out;
  transition-delay: 1.6s;
}

.MobilePermanentSearch::before {
  content: '';
  position: absolute;
  inset: -2em;
}

.MobilePermanentSearch svg {
  fill: var(--ui-color-dim);
}

.MobilePermanentSearch.hidden {
  opacity: 0;
  pointer-events: none;
  transition-delay: 0s;
  transition-duration: .5s;
}