
.SearchProject .ProjectHint {
  position: fixed;
  background-color: black;
  padding: 4px;
  pointer-events: none;
  transition: opacity .3s ease-in-out;
}

.SearchProject .ProjectHint.hidden {
  opacity: 0;
}

.SearchProject .ProjectHint .Title {
  font-size: 1em;
}

.SearchProject .ProjectHint .Subtitle {
  font-size: .66em;
  text-transform: none;
}
