
input,
button,
.button {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
}

button {
  border: none;
}

input {
  border: unset;
}

input.border,
button.border,
.button.border {
  border: solid 1px currentColor;
  border-radius: 2px;
  padding: .3em .6em;
}

button.blank,
.button.blank {
  padding: 0;
}

input.border-bottom,
button.border-bottom,
.button.border-bottom {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: 0;
}

input::placeholder {
  color: inherit;
  opacity: .5;
  font-style: italic;
}

input:focus,
button:focus,
.button:focus {
  outline: none;
}

button,
.button {
  cursor: pointer;
  user-select: none;
}

button.border.disabled,
.button.border.disabled {
  font-style: italic;
  opacity: .5;
  pointer-events: none;
}

button.link,
.button.link {
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
}

@media (hover) {
  button.link:focus,
  .button.link:focus,
  button.link:hover,
  .button.link:hover {
    text-decoration: underline;
  }
}

button.blank,
.button.blank {
  border: unset;
}

