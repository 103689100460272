
@font-face {
  font-family: 'Fraktion Sans';
  font-weight: 400;
  src: url(PPFraktionSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Sans';
  font-weight: 500;
  src: url(PPFraktionSans-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Sans';
  font-weight: 600;
  src: url(PPFraktionSans-Semibold.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Sans';
  font-weight: 700;
  src: url(PPFraktionSans-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Fraktion Sans';
  font-weight: 900;
  src: url(PPFraktionSans-Black.woff2) format('woff2');
}
