@import url(previews.mobile.css);
@import url(previews.hover.css);

.NewsPreviews {
  padding-top: var(--grid-padding-top);
}

.NewsPreviews .RowsWrapper {
  height: 100%;
  overflow: hidden scroll;
}

.NewsPreviews .Rows section {
  padding-bottom: var(--grid-padding-bottom);
}

.NewsPreviews .Row {
  padding:
    2em 
    calc(var(--grid-padding-right) + 2em)
    2em
    calc(var(--grid-padding-left) + 2em);
  gap: 4em;
  height: 18em;
  
  cursor: pointer;
}

.NewsPreviews .Row h2 {
  font-size: 14px;
  text-transform: uppercase;
}

.NewsPreviews .Row .Date .Day,
.NewsPreviews .Row .Date .Year {
  font-size: 3em;
  line-height: .8em;
  font-weight: 900;
}

.NewsPreviews .Row .Date .Month {
  font-size: 1.9em;
  font-weight: 900;
  /* letter-spacing: -.025em; */
  line-height: .8em;
}

.NewsPreviews .Row .Date {
  padding: 1.5em .5em;
  gap: 1.5em;
}

.NewsPreviews .Row .Date::before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: .6em;
  width: 100%;
  height: 1px;
  background-color: currentColor;
}

.NewsPreviews .Row .Text,
.NewsPreviews .Row .Image {
  flex: 1;
}

.NewsPreviews .Row .Text {
  display: flex;
  flex-direction: column;
}

.NewsPreviews .Row .Text .NewsCategory {
  line-height: 1em;
  font-weight: 700;
  letter-spacing: .1em;
}

.NewsPreviews .Row .Text .Title {
  line-height: 1em;
  font-weight: 900;
}

.NewsPreviews .Row .Title {
  font-size: 2em;
  text-transform: uppercase;
  flex: 1 0 0;
  min-height: 1em;
  max-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NewsPreviews .Row .Description {
  flex: 0 0 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NewsPreviews .Row .Text button {
  align-self: flex-start;
  padding: .25em 0;
}

.NewsPreviews .Row .Text button::before {
  content: '';
  position: absolute;
  inset: -.75em -1em;
  transform: translateY(-.1em);
}
