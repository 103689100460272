
@font-face {
  font-family: 'DIN Pro';
  src: url(DINPro.otf) format('opentype');
}

@font-face {
  font-family: 'DIN Pro';
  font-style: italic;
  src: url(DINPro-Italic.otf) format('opentype');
}


@font-face {
  font-family: 'DIN Pro';
  font-weight: 500;
  src: url(DINPro-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 500;
  font-style: italic;
  src: url(DINPro-MediumItalic.otf) format('opentype');
}


@font-face {
  font-family: 'DIN Pro';
  font-weight: 600;
  src: url(DINPro-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 600;
  font-style: italic;
  src: url(DINPro-BoldItalic.otf) format('opentype');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 800;
  src: url(DINPro-Black.otf) format('opentype');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 800;
  font-style: italic;
  src: url(DINPro-Black.otf) format('opentype');
}

