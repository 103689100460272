
.MobileMenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
  padding: var(--grid-padding-top) var(--grid-padding-right) var(--grid-padding-bottom) var(--grid-padding-left);
}

.MobileMenu > div {
  padding-top: 48px;
  padding-bottom: 32px;
}

.MobileMenu .ui {
  font-size: 1.5em;
  text-transform: uppercase;
}

.MobileMenu a.Link {
  text-decoration: none;
  color: inherit;
}

.MobileMenu a.Link:visited {
  color: unset;
}

.MobileMenu .NavButton,
.MobileMenu .LocaleButton {
  font-weight: 400;
}

.MobileMenu .LocaleButton.underline::before {
  left: .3em;
  right: .25em;
  bottom: -.2em;
  height: 1.5px;
}

.MobileMenu .NavButton {
  /* color: var(--ui-color-dim); */
  --ui-color: var(--ui-color-dim);
}

.MobileMenu .IconWrapper {
  font-size: 24px;
}

.MobileMenu .IconWrapper svg {
  width: 20px;
  height: 20px;
}

.MobileMenu .Email {
  font-size: 1.5em;
}

.MobileMenu .Companies {
  font-size: 1.5em;
  padding: 0 24px;
}

.MobileMenu .Company span {
  padding: 0 8px;
}

.MobileMenu .Company .Icon svg {
  width: 16px;
  height: 16px;
}

.MobileMenu .Company .Icon.Location svg {
  transform: scale(1.35);
}
