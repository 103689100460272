.ProjectContent.MediaGallery {
  --scroll-inner-height: 200px;
  height: calc(var(--vh) + var(--scroll-inner-height));
  width: 100%;
  /* margin: 120px 0; */
}

.ProjectContent.MediaGallery .Wrapper {
  /* position: fixed; */
  position: absolute;
  top: 10px;
  width: 100vw;
  height: var(--vh);
  /* pointer-events: none; */
  overflow: hidden;
  z-index: 1;
  /* background-color: #0003; */
}

.ProjectContent.MediaGallery .Wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ProjectContent.MediaGallery .Wrapper > .Header {
  flex: 0 0 auto;
  color: var(--ui-highlight-color);
  padding-bottom: 32px;
}

.ProjectContent.MediaGallery .Wrapper > .Header > div {
  padding: 0 var(--grid-inset);
  display: flex;
  flex-direction: column;
}

.ProjectContent.MediaGallery .Wrapper > .Header > div > * + * {
  margin-top: var(--vertical-gap);
}

.ProjectContent.MediaGallery .Wrapper > .Slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ProjectContent.MediaGallery .Wrapper > .Footer {
  flex: 0 0 80px;
  padding-top: 32px;
}

.ProjectContent.MediaGallery .Wrapper .SliderFrame {
  position: absolute;
  background-color: var(--ui-color);
  transition: filter .3s ease-in-out, opacity .2s ease-in-out;
  cursor: pointer;
}

.ProjectContent.MediaGallery .Wrapper .SliderFrame:not(.highlighted) {
  filter: saturate(0);
  opacity: .15;
}

.ProjectContent.MediaGallery .Wrapper .SliderFrame:not(.highlighted):hover {
  opacity: .25;
}

.ProjectContent.MediaGallery .Slider .MediaContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.ProjectContent.MediaGallery .Counter {
  position: absolute;
  top: 100%;
  left: 50%;
  font-weight: 100;
  font-size: 64px;
  transform: translate(-50%, -50%);
}