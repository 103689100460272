.Home {
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh);
  overflow: hidden;
}

.Home .HomeSplash {
  justify-content: center;
}

:root:not(.mobile) .Home .HomeSplash > div {
  padding-left: var(--grid-padding-left);
}

:root.mobile .Home .HomeSplash > div {
  padding: 0 var(--grid-inset);
}

.Home .HomeSplash .Title {
  text-align: left;
  white-space: pre;
  color: var(--ui-color-max);
  font-size: 5em;
  letter-spacing: .2em;
  line-height: 1.25em;
}

.Home .HomeSplash .BigButtons {
  display: flex;
  flex-direction: row;
  gap: 2em;
}

.mobile .Home .HomeSplash .BigButtons {
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}

.Home .HomeSplash .BigButtons button {
  text-transform: uppercase;
  font-family: 'Fraktion Mono', sans-serif;
  letter-spacing: .25em;
  font-size: 2em;
  font-weight: 900;
}

.mobile .Home .HomeSplash .BigButtons button {
  font-size: 1.5em;
}

.Home .HomeSplash .BigButtons button::before {
  content: '↘';
}