
.Cover {
  --text-margin: 64px;
}

.Cover .CoverWrapper {
  width: 80%;
  align-self: stretch;
}

:is(.column-1, .column-3) .Cover .CoverWrapper {
  width: 100%;
}

.Cover .CoverWrapper .Texts {
  flex: 1;
}

.column-3 .Cover .CoverWrapper .Texts {
  flex: 2;
}

.column-1 .Cover .CoverWrapper .Texts {
  flex: unset;
  padding-left: calc(var(--grid-padding-left) - 2px);
}

.Cover .CoverWrapper .Texts > * {
  padding-right: var(--text-margin);
}

.column-1 .Cover .CoverWrapper .Texts > * {
  padding-right: 0;
}

.column-3 .Cover .CoverWrapper .Texts > * {
  padding-left: 64px;
}

.Cover .CoverWrapper .Media {
  flex: 2;
}

.Cover.pointer-disabled {
  pointer-events: none;
}

.App .Cover .Title {
  /* margin-top: var(--text-margin); */
  font-size: 2.8em !important;
  white-space: pre-line;
}

.column-1 .Cover .Title {
  margin-top: 0;
  font-size: 2.2em !important;
}

.Cover .Title > *:first-child{
  margin-top:-.25em;
}
.Cover .Title > *:last-child{
  margin-bottom:-.25em;
}

.mobile .Cover .Title {
  white-space: unset;
  line-height: 1em;
}

.Cover h2 {
  font-size: 1em;
  font-weight: normal;
}

.Cover h2,
.Cover p,
.Cover .Tags {
  padding-top: 32px;
}

.column-1 .Cover h2,
.column-1 .Cover p,
.column-1 .Cover .Tags {
  padding-top: 16px;
  padding-bottom: 12px;
}

.Cover .Texts {
  text-shadow: var(--text-shadow);
}

.Cover .Texts p {
  /* width: 66%; */
}

/* .Cover .Media {
  position: absolute;
  z-index: -1;
  top: 0;
  right: var(--slider-top-margin);
  left: calc(var(--slider-top-margin) * 1.66);
  height: 100%;
  object-fit: cover;
  object-position: 0;
}

.mobile .Cover .Media {
  right: -80px;
  left: 96px;
  width: unset;
} */
