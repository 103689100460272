@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=block');

.LiveInspector {
  --ui-color: #999;
  --ui-color-dim: #666;
  --ui-color-highlight: #ccc;

  --ui-background-color: #18171b;
  --ui-background-color-highlight: #201e25;
  --ui-background-color-dim: #111012;

  --ui-color-default: var(--ui-color);
  --ui-background-color-default: var(--ui-background-color);
}

.LiveInspector .highlight {
  color: var(--ui-color-default);
  --ui-color-default: var(--ui-color-highlight);
  --ui-background-color-default: var(--ui-background-color-highlight);
}

.LiveInspector .dim {
  color: var(--ui-color-default);
  --ui-color-default: var(--ui-color-dim);
  --ui-background-color-default: var(--ui-background-color-dim);
}

.LiveInspector {
  width: 240px;
  padding: 4px;
  border-radius: 2px;
  color: var(--ui-color-default);
  background-color: var(--ui-background-color-default);
  font-family: 'Fira Code', monospace;
  font-size: 12px;
}

.LiveInspector h1 {
  cursor: move;
  user-select: none;
}

.LiveInspector h1,
.LiveInspector h2 {
  font-size: inherit;
  font-weight: inherit;
}

.LiveInspector > p {
  font-size: .8em;
  opacity: .66;
}
