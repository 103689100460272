.Lightbox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #16151B;

  display: flex;
  flex-direction: column;
}

.Lightbox .LighboxBackButton {
  padding: 16px;
  position: absolute;
  color: #fffb;
}

.Lightbox .LighboxBackButton:hover {
  color: var(--ui-color-max);
}

.Lightbox .MediaWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ui-color-max);
  overflow: hidden;
}

.Lightbox .MediaWrapper .ImageWrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;
}

.Lightbox .MediaWrapper .ImageWrapper > div > * {
  width: 100%;
  height: 100%;
}

.Lightbox .NavButton {
  padding: 8px;
  --size: 48px;
  width: var(--size);
  height: var(--size);
}
.Lightbox .NavButton.Previous {
  left: 0;
}
.Lightbox .NavButton.Previous svg #arrow {
  transform: translate(10px, 0) scale(-1, 1) translate(-10px, 0);
}
.Lightbox .NavButton.Next {
  right: 0;
}
.Lightbox .NavButton svg {
  fill: currentColor;
}

.Lightbox .NavButton:hover {
  color: var(--ui-color-max);
  stroke-width: 2px;
}

.Lightbox footer .Line {
  /* width: 25%; */
  height: 1px;
  background-color: #ffffff19;
  margin-bottom: 16px;
}
