ul.Tags {
  font-size: 12px;
  padding: 0;
  /* padding-top: 16px; */
  flex-wrap: wrap;
  --spacing: 8px;
}

ul.Tags.tight {
  --spacing: 2px;
}

ul.Tags.center {
  padding-left: var(--spacing);
}

ul.Tags li.Tag {
  list-style: none;
  margin-right: var(--spacing);
  margin-bottom: var(--spacing);
}

ul.Tags .ClearButton {
  position: absolute;
  right: -32px;
  margin-left: 8px;
}

.mobile ul.Tags li.Tag {
  margin-bottom: 0;
}

li.Tag {
  white-space: nowrap;
  cursor: pointer;
  color: var(--ui-tag) !important;
}

li.Tag:hover {
  color: var(--ui-tag-max) !important;
}
li.Tag:not(.selectable) {
  padding-right: 8px;
}
li.Tag.selectable,
li.Tag.selected {
  padding: 2px 8px;
}
li.Tag.selected {
  background-color: var(--ui-color);
  color: black !important;
}

/* responsive */
:root.mobile ul.Tags {
  font-size: 13px;
}

:root.mobile li.Tag {
  padding: 2px 4px;
}
