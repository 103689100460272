
.SearchProject .SliderIndex {
  position: absolute;
  top: var(--texts-margin-top);
  left: 0;
  width: var(--slider-top-margin);
  font-size: 64px;
  font-weight: 100;
  letter-spacing: 0;
  align-items: stretch;
}

.SearchProject .SliderIndex .index {
  line-height: .7em;
}

.SearchProject .SliderIndex .index::after {
  content: '';
  position: absolute;
  top: 110%;
  left: 4px;
  width: calc(100% - 10px);
  height: 1px;
  background-color: var(--ui-color);
}



/* RESPONSIVE */

.mobile .SearchProject .SliderIndex {
  top: 0;
  font-size: 40px;
  left: var(--slider-top-margin);
  width: unset;
}
