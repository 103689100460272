.NewsPreviews .MobileRow .Image {
  height: 12em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1.2em;
}

.NewsPreviews .MobileRow .NewsBody {
  padding: .8em 1.2em;
  gap: .4em;
}

.NewsPreviews .MobileRow .NewsBody .Date,
.NewsPreviews .MobileRow .NewsBody h1 {
  font-size: 1.4em;
  font-weight: 600;
  letter-spacing: .1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
