
.SearchProject .NavArrow {
  position: fixed;
  opacity: .5;
  height: var(--vh);
  top: 0;
}

.SearchProject .NavArrow.previous {
  left: 0;
  transform: scaleX(-1);
}

.SearchProject .NavArrow.next {
  right: 0;
}

.SearchProject .NavArrow svg {
  --withdrawal: -10px;
  position: absolute;
  /* bottom: var(--grid-padding-bottom); */
  top: calc((100% - 200px) / 2);
  right: var(--withdrawal);
  transition: all .3s;
  stroke: var(--ui-color);
}

.column-1 .SearchProject .NavArrow svg {
  top: unset;
  bottom: 32px;
  right: calc(var(--withdrawal) - 20px);
}

html:not(.touch) .SearchProject .NavArrow:hover {
  opacity: 1;
}

html:not(.touch) .SearchProject .NavArrow:hover svg {
  right: 4px;  
}

.SearchProject .NavArrow.disabled {
  pointer-events: none;
  opacity: .33;
}
