.UnifiedSearch {
  background-color: #fff;
  /* background-color: #fffc; */
  /* backdrop-filter: blur(32px); */
  transition: opacity .2s ease-in-out;
}

.UnifiedSearch:not(.visible) {
  opacity: 0;
  /* visibility: hidden; */
  pointer-events: none;
}

.UnifiedSearch .Main {
  padding-bottom: 0;
}

:root:not(.mobile) .UnifiedSearch .Main {
  gap: 8em;
}

:root.mobile .UnifiedSearch .Main {
  gap: calc(var(--grid-padding-top) / 2);
  padding-left: 0;
  padding-right: 0;
}

.UnifiedSearch .Top {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.UnifiedSearch .Bottom {
  flex: 2;
}

:root.mobile .UnifiedSearch .Bottom {
  flex-grow: 3;
}

:root:not(.mobile) .UnifiedSearch .Bottom {
  gap: 8em;
}

.UnifiedSearch input {
  padding: .33em 0;
  text-align: center;
  text-transform: uppercase;
}

:root:not(.mobile) .UnifiedSearch input {
  font-size: 8em;
  width: 50%;
}

:root.mobile .UnifiedSearch input {
  font-size: 4em;
  width: 100%;
}

.UnifiedSearch .EntriesColumn {
  --font-size: 22px;
}

.UnifiedSearch .ColumnTitle {
  text-transform: uppercase;
  padding-bottom: .5em;
  /* border-bottom: 1px solid currentColor; */
  margin-bottom: 2em;
  font-size: var(--font-size);
}

.UnifiedSearch .Results {
  gap: .33em;
}

.UnifiedSearch .Results .Result {
  gap: 2em;
  overflow: hidden;
  justify-content: space-between;
  cursor: pointer;
  font-size: var(--font-size);
  font-weight: 900;
}

.UnifiedSearch .Results .Result:hover {
  background-color: #eee;
}

.UnifiedSearch .Results .ResultTitle,
.UnifiedSearch .Results .ResultDescription {
  white-space: nowrap;
  max-width: 66%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

.UnifiedSearch .Results .ResultTitle {
  flex: 0 0 auto;
}

.UnifiedSearch .Results .ResultDescription {
  flex: 1;
  opacity: .33;
  text-align: right;
}

.UnifiedSearch .Results .NoResult {
  /* font-style: italic; */
  /* opacity: .33; */
  color: var(--ui-color-dim);
  text-align: left;
  font-size: var(--font-size);
  font-weight: 600;
}
