
.HUD .TopMenu {
  height: 32px;
  width: 260px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  /* font-size: 14px;
  font-weight: 600; */
}

.HUD .TopMenu .DivSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow: visible;
  text-transform: uppercase;
}

.HUD .TopMenu .DivSwitch > * {
  flex: none;
}

.HUD .TopMenu a {
  text-decoration: none;
  /* color: inherit; */
}

.HUD .TopMenu a,
.HUD .TopMenu span {
  padding: 4px 0;
  /* font-size: 13px; */
  line-height: 1em;
  transition: var(--color-transition);
}

.HUD .TopMenu span:hover {
  transition: none;
}
