
.HUD {
  position: fixed;
  z-index: 20;
  
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--vh);
  display: flex;
  justify-content: space-between;
  color: var(--ui-current-color);
  /* user-select: none; */
  --color-transition: color .3s ease-out;
}

.HUD > * {
  position: absolute;
}

.HUD .BottomMenu {
  flex-direction: row;
  width: 150px;
  height: 32px;
}

.HUD .BottomMenu.left .DivSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.HUD .BottomMenu.right .DivSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.HUD .Dash {
  background-color: var(--ui-color);
  height: 1px;
  width: 16px;
}
