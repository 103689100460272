
.MobileResults {
  gap: 2em;
  overflow: hidden scroll;
}

.MobileResults .Image {
  height: 12em;
  background-color: #ededed;
  justify-content: end;
}

.MobileResults .Image p {
  color: var(--ui-color);
}

.MobileResults .Image,
.MobileResults .Texts {
  padding: .8em 1.2em;
}

.MobileResults .UnifiedSearchTitle,
.MobileResults .UnifiedSearchDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
}

.MobileResults .UnifiedSearchDescription {
  color: var(--ui-color-max);
}
