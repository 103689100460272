.Doc {
  height: var(--vh);
  overflow: scroll;
}

.Doc > .Summary {
  flex: 0 0 160px;
  position: sticky;
  top: 0;
  font-size: 10px;
  padding: 16px;
}

.Doc > .Summary h1 {
  font-size: 1.2em;
  margin-bottom: 8px;
}

.Doc > .Summary ul {
  padding-left: 0;
}

.Doc > .Summary li {
  list-style: none;
  cursor: pointer;
}

.Doc > .Summary li:hover {
  color: var(--ui-color-max);
}

.Doc > .Summary li + li {
  margin-top: 4px;
}

.Doc > .Summary li .Indice {
  color: plum;
  padding-right: 4px;
}

.Doc > .Content {
  --text-color-base: #7a7a84;
  --text-color-highlight: #a8a8b3;

  flex: 1;
  min-height: var(--vh);
  padding: 64px;
  background-color: var(--background-color);
  color: var(--text-color-base);
  line-height: 1.4em;
}

.Doc > .Close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 32px;
  width: 32px;
  height: 32px;
}

.Doc > .Close svg {
  width: 100%;
  height: 100%;
}

.Doc > .Content a {
  color: var(--text-color-highlight);

  &:hover {
    color: white;
  }
}

.Doc > .Content * {
  margin: 0.3em 0;
}

.Doc > .Content h1,
.Doc > .Content h2,
.Doc > .Content h3,
.Doc > .Content h4,
.Doc > .Content h5,
.Doc > .Content h6 {
  margin: 3em 0 1em 0;
  cursor: pointer;
}

.Doc > .Content h1 span.index,
.Doc > .Content h2 span.index,
.Doc > .Content h3 span.index,
.Doc > .Content h4 span.index,
.Doc > .Content h5 span.index,
.Doc > .Content h6 span.index {
  opacity: 0.8;
  font-size: 0.75em;
}

.Doc > .Content > *:first-child {
  margin-top: 0;
}

.Doc > .Content h1 {
  color: #fffd;
}

.Doc > .Content h2 {
  color: #fffd;
}

.Doc > .Content h3,
.Doc > .Content h4 {
  color: plum;
}

.Doc > .Content strong {
  color: #6de3bb;
}

.Doc > .Content em {
  color: #a0b6ec;
}

.Doc > .Content pre {
  font-size: 0.7em;
  line-height: 1.5em;
  padding: 8px;
  background-color: #1a1922;
  border: solid 1px #2d2b3a;
  border-radius: 8px;
}

.Doc > .Content p code,
.Doc > .Content li > code {
  font-size: 0.9em;
  padding: 2px 4px;
  background-color: #2d2b3a;
  white-space: pre;
  border-radius: 2px;
}
