
@font-face {
  font-family: 'Ovvo Blender Pro';
  font-weight: 100;
  src: url(Blender-Pro-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Ovvo Blender Pro';
  font-weight: 100;
  font-style: italic;
  src: url(Blender-Pro-Thin-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Ovvo Blender Pro';
  src: url(Blender-Pro-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'Ovvo Blender Pro';
  font-weight: 500;
  src: url(Blender-Pro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Ovvo Blender Pro';
  font-weight: 600;
  src: url(Blender-Pro-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Ovvo Blender Pro';
  font-weight: 600;
  font-style: italic;
  src: url(Blender-Pro-Bold-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Ovvo Blender Pro';
  font-weight: 900;
  src: url(Blender-Pro-Heavy.ttf) format('truetype');
}
