
.SearchProject .PixelView {
  padding-left: var(--grid-padding-left);
  padding-right: var(--grid-padding-right);
  --col: 4;
  --row: 3;
  --spacing: 4px;
}

.mobile .SearchProject .PixelView {
  padding-left: var(--spacing);
  padding-right: var(--spacing);
  padding-bottom: var(--spacing);
}

.SearchProject .PixelView .Grid {
  position: absolute;
  width: calc(100% + var(--spacing));
  height: calc(100% + var(--spacing));
  flex-wrap: wrap;
}

.SearchProject .PixelView .Cell {
  position: absolute;
  overflow: hidden;
}

.SearchProject .PixelView .Cell .Thumbnail {
  transition: opacity .2s ease-in-out;
}

.SearchProject .PixelView .Cell .Thumbnail > * {
  transition: all .3s cubic-bezier(.3, .3, .3, 1);
}

.SearchProject .PixelView .Cell .Thumbnail .Image {
  background-position: center;
  background-size: cover;
}

.mouse .SearchProject .PixelView .Cell:hover .Thumbnail .Image {
  transform: scale(1.2);
}

.SearchProject .PixelView .Cell .Thumbnail .Frame {
  border: solid 1px #f1f7ff;
  opacity: 0;
}

.mouse .SearchProject .PixelView .Cell:hover .Thumbnail .Frame {
  opacity: 1;
}

.SearchProject .PixelView .Cell .Disabled {
  transition: opacity .2s ease-in-out;
  opacity: 0;
}

.SearchProject .PixelView .Cell.dim {
  pointer-events: none;
}

.SearchProject .PixelView .Cell.dim .Thumbnail {
  opacity: 0;
  background-repeat: no-repeat;
}

.SearchProject .PixelView .Cell.dim .Disabled {
  opacity: 1;
}

.SearchProject .PixelView .BestGrid {
  overflow: hidden;
}

