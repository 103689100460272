
.HoverFrame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  --border-size: 1px;
  --border-color: var(--ui-secondary-color);
  border: solid #fff3 var(--border-size);
  background-color: #0002;
  z-index: 1;
  transition: opacity .1s ease-out;
}
