.OvvoLogo2023 {
  --mask-color: black;
  cursor: pointer;
  mix-blend-mode: screen;
  width: fit-content;
}

.OvvoLogo2023 svg {
  fill: currentColor;
}

.light-theme .OvvoLogo2023 {
  mix-blend-mode: multiply;
  --mask-color: white;
}