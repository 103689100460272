.Studios .Intro {
  /* force bottom padding to avoir "arrow scroll invitation" */
  padding-bottom: 96px;
}

.Studios .Intro h1 {
  font-size: 2em;
  text-align: center;
}

.Studios .Intro > div {
  text-align: center;
}

.Studios .Intro .Spacing {
  flex: 0 0 1.2em;
}

.Studios .fullframe {
  flex: 0 0 var(--vh);
}
