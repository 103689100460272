@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=block);
@import url(assets/fonts/Blender-Pro/Blender-Pro.css);
@import url(assets/fonts/Din-Pro/DINPro.css);
@import url(assets/fonts/FraktionMono/FraktionMono.css);
@import url(assets/fonts/FraktionSans/FraktionSans.css);

html {
  overflow: hidden;
  --vh: 100vh;
}

html, body {
  overflow-x: hidden;
  overscroll-behavior-x: none;
}

#canvas-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--vh);
}

.App {
  --ui-default: #abb1be;
  --ui-default-max: #e5e7eb;
  --ui-default-dim: #5f6772;

  --ui-highlight: #c7ccd7;
  --ui-highlight-max: #e5e7ebff;
  --ui-highlight-dim: #b6bbc4;

  --ui-link: #76EAE9bb;
  --ui-link-max: #76EAE9ff;
  --ui-link-dim: #76EAE950;

  --ui-tag: #c7ccd7;
  --ui-tag-max: #e5e7ebff;
  --ui-tag-dim: #b6bbc4;

  --ui-color: var(--ui-default);
  --ui-color-max: var(--ui-default-max);
  --ui-color-dim: var(--ui-default-dim);

  --ui-current-color: var(--ui-color);
  --ui-color-transition: color .2s ease-out;

  /* --text-shadow: 1px 1px 1px #000000cc; */
  --text-shadow: 1px 2px 2px #0000006b;

  /* This will be overriden by state/layout "globalStyle" */
  --grid-padding-top: 0px;
  --grid-padding-right: 0px;
  --grid-padding-bottom: 0px;
  --grid-padding-left: 0px;
  --grid-padding: var(--grid-padding-top) var(--grid-padding-right) var(--grid-padding-bottom) var(--grid-padding-left);
}

.App {
  position: fixed;
}

.grid-padding {
  padding:
    var(--grid-padding-top)
    var(--grid-padding-right)
    var(--grid-padding-bottom)
    var(--grid-padding-left);
}

.ui-highlight {
  --ui-color: var(--ui-highlight);
  --ui-color-max: var(--ui-highlight-max);
  --ui-color-dim: var(--ui-highlight-dim);
}

.ui-link {
  --ui-color: var(--ui-link);
  --ui-color-max: var(--ui-link-max);
  --ui-color-dim: var(--ui-link-dim);
}

.ui-tag {
  --ui-color: var(--ui-tag);
  --ui-color-max: var(--ui-tag-max);
  --ui-color-dim: var(--ui-tag-dim);
}

button.ui-dim,
.button.ui-dim,
.ui-dim {
  --ui-current-color: var(--ui-color-dim);
  color: var(--ui-current-color);
}

button,
.button {
  --ui-current-color: var(--ui-color);
  color: var(--ui-current-color) !important;
  transition: var(--ui-color-transition);
  padding: 0;
}

button:hover,
.button:hover,
button.hover,
.button.hover {
  --ui-current-color: var(--ui-color-max);
}

.App.monochrome {
  --ui-highlight: #e5e7ebcc;
  --ui-highlight-max: #e5e7ebff;
  --ui-highlight-dim: #e5e7eb50;

  --ui-link: #e5e7ebcc;
  --ui-link-max: #e5e7ebff;
  --ui-link-dim: #e5e7eb50;

  --ui-tag: #e5e7ebcc;
  --ui-tag-max: #e5e7ebff;
  --ui-tag-dim: #e5e7eb50;
}

.App {
  /* NOTE: column width is top defined (<html />) at runtime. */
  /* --column-width: 200px; */
  --grid-inset: 8px;
  --vertical-gap: 16px;
  
  --background-color: #16151B;
  background-color: var(--background-color);
  color: var(--ui-current-color);
  width: 100%;
  min-height: var(--vh);
  font-family: 'Fraktion Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 1.4;
}

.column-1 .App {
  font-size: 16px;
}

.App .Title {
  font-family: 'Fraktion Mono', sans-serif;
  font-size: 3.6em;
  font-weight: 700;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.0125em;
}

.column-1 .App .Title {
  font-size: 2.8em;
}

.App .Subtitle {
  font-family: 'Fraktion Mono', sans-serif;
  font-weight: 400;
  font-size: 1.15em;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}



/* Fixed solution for now. F*** you iOS. */
.App > .DivRoute > div {
  position: fixed;
  top: 0;
  width: 100vw !important;
  height: var(--vh) !important;
  overflow: hidden;
}

.ui {
  font-family: 'Fraktion Sans', sans-serif;
  font-weight: 600;
  letter-spacing: .15em;
  font-size: 12px;
  color: var(--ui-current-color);
}

.ui.ui-uppercase {
  letter-spacing: .16em;
  text-transform: uppercase;
}

.ui.ui-bold {
  font-weight: 900;
}

.ui.ui-bigger {
  font-size: 16px;
  letter-spacing: .1em;
}

.App a {
  text-decoration: none;
}

.dark-theme .title-shadow {
  text-shadow: 0 0 white, .025em .025em .1em #00000080;
  opacity: .8;
}


.light-theme .title-shadow {
  text-shadow: 0 0 black, .025em .025em .1em white;
  opacity: .8;
}
