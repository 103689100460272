.Scrollbar {
  --thickness: 8px;
  position: absolute;
}
.Scrollbar.horizontal {
  width: 100%;
  height: calc(var(--thickness) * 2);
  left: 0;
  bottom: 0;
}
.Scrollbar.vertical {
  width: calc(var(--thickness) * 2);
  height: 100%;
  right: calc(var(--thickness) * -1);
  top: 0;
}

.Scrollbar .Bar {
  position: absolute;
  background-color: hsl(215, 7%, 34%);
  transition: 
    background-color .2s ease-out,
    transform .2s ease-out;
}
.Scrollbar.horizontal .Bar {
  width: 160px;
  height: calc(var(--thickness) * 2);
  border-radius: var(--thickness) var(--thickness) 0 0;
}
.Scrollbar.vertical .Bar {
  width: calc(var(--thickness) * 2);
  height: 160px;
  border-radius: var(--thickness) 0 0 var(--thickness);
}

.Scrollbar.vertical.grabbed .Bar {
  transform: translateX(calc(var(--thickness) * -0.5));
  background-color: hsl(215, 7%, 44%);
}
