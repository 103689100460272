.CompanySectionJune23 {
  --vertical-padding: 104px;
  height: var(--vh);
  padding: 
    var(--vertical-padding) 
    var(--grid-padding-right) 
    var(--vertical-padding) 
    var(--grid-padding-left);
  text-align: center;
}

.CompanySectionJune23 img {
  width: 100%;
  max-width: 20em;
}