
.NewsHeader {
  padding-bottom: 4em;
}

.NewsHeader > .button {
  transition: opacity 0.1s ease-in-out;
}

.NewsHeader > .button::before {
  content: '';
  position: absolute;
  inset: -2em;
}

.NewsHeader > .button.dim {
  color: var(--ui-color-max) !important;
}

.NewsHeader > .button:hover {
  color: var(--ui-color-dim) !important;
}