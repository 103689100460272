.ScrollInvitation {
  --width: 200px;
  position: absolute;
  width: var(--width);
  left: calc((100% - var(--width)) / 2);
  bottom: calc(var(--grid-padding-bottom) / 2 - 15px);
  font-size: 18px;
}

.ScrollInvitation .Label {
  padding-bottom: 32px;
  font-size: 18px;
}

.mobile .ScrollInvitation svg {
  transform: scale(.66);
}

.mobile .ScrollInvitation .Label {
  padding-bottom: 8px;
  font-size: 16px;
}
